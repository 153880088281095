import { errorCodes, envs } from 'src/constants'

export function raise(err: Error, code: string = errorCodes.ERROR) {
  if (process.env.NODE_ENV === envs.PRODUCTION) {
    // report error
    alert(`Ett fel har uppstått:\n${err.message}\n\n(${code})`)
    window.location.href = window.location.href
  } else {
    console.error(err)
  }
  // @ts-ignore
  err.code = code
  return err
}

export function warn(err: Error) {
  if (process.env.NODE_ENV === envs.PRODUCTION) {
    // report error
  } else {
    console.warn(err)
  }
}
