import * as React from 'react'

import ListProduct from 'src/components/ListProduct/ListProduct'

import styles from './ProductGrid.module.scss'

type Props = {
  products: object[]
  fluidStyle?: boolean
}

const ProductGrid = ({ products }: Props) => {
  let gridStyle = products.length > 1 ? styles.grid : [styles.grid, styles.single].join(' ')
  return (
    <div className="container">
      <div className={gridStyle}>
        {products.map((product: any, index: number) => (
          <div key={index}>
            <ListProduct key={index} product={product} />
          </div>
        ))}
        <div className={styles.ghost} />
        <div className={styles.ghost} />
      </div>
    </div>
  )
}

export default ProductGrid
