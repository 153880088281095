import * as React from 'react'
import { Link } from 'gatsby'
import { inject, observer } from 'mobx-react'

import Image from 'src/components/UI/Image/Image'
import Price from 'src/components/Product/Price/Price'
import { getPrice } from 'src/utils/product'

// import QuickAddToCart from './QuickAddToCart'
import styles from './ListProduct.module.scss'

type Props = {
  product: any
  preview?: any
  cart?: any
}

@inject('preview', 'cart')
@observer
export default class ListProduct extends React.Component<Props> {
  render() {
    let { product, preview, cart } = this.props
    if (preview.previewProducts) {
      product = preview.getCurrentProductContext(product.product)
    }
    const { name, excerpt, canonicalUri, variantName } = product
    const image = product.media[0]
    const variant =
      product && product.items && product.items.length === 1
        ? product.items[0]
        : null

    return (
      <Link to={`/${canonicalUri}`} className={styles.listProduct}>
        <div className={styles.imageWrapper}>
          <Image
            className={styles.image}
            src={image.sources.full.url}
            alt={name}
          />
          {/* {variant && <QuickAddToCart variant={variant} />} */}
        </div>
        <div className={styles.productInfo}>
          <div>
            <div className={styles.name}>{`${name}, ${variantName}`}</div>
          </div>
          <div className={styles.infoBottom}>
            <div>
              <Price
                noStyle={true}
                price={getPrice(product.markets, cart.country)}
              />
            </div>
          </div>
        </div>
      </Link>
    )
  }
}
