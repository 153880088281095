import countries from 'src/json/countries.json'
import pricelists from 'src/json/pricelists.json'
import products from 'src/json/products.json'
import { warn } from 'src/exception'
import categories from 'src/json/categories.json'

const find = (arr: any[] = [], key: string, value: any) =>
  arr.filter((entry: any) => entry[key] === value).slice(0)[0] || null

type Price = null | {
  price: string
  pricelist: {
    countries: string[]
    currency: {
      currency: string
      decimalDigits: string
      decimalPoints: string
    }
  }
}

export function isUnavailable(productMarkets: any, country: string): boolean {
  if (country) {
    const countryData = find(countries, 'country', country)
    if (countryData) {
      const { market } = countryData
      const productMarket = find(productMarkets, 'market', market)
      if (productMarket) {
        return true
      }
    }
  }
  return false
}

export function getFeaturedProducts(): object[] {
  return products.filter((product: any) => product.featuredProduct)
}

export function getProductsByIds(productIds: string[]): object[] {
  return products.filter((product: any) => productIds.includes(product.product))
}

export function getProductById(productId: string): any {
  return products.filter((product: any) => productId === product.product)[0]
}

export function isItemAvailable(country: string, stock: any) {
  if (country) {
    const countryData = find(countries, 'country', country)
    if (countryData) {
      const { market } = countryData
      if ((stock[market] && stock[market] > 0) || stock === 'yes') {
        return true
      }
    }
  }
  return false
}

export function getProductComponentData(product: any, productInfo: any) {
  const {
    name,
    canonicalUri,
    relatedProducts,
    description,
    infoCareGuide,
    infoFitSize,
    infoDetails,
    excerpt,
  } = product

  const { shippingInfo } = productInfo

  const breadcrumbs = canonicalUri.split('/')
  let bredcrumbsItems = []
  breadcrumbs.forEach((item: string) => {
    Object.keys(categories).forEach((key) => {
      if (categories[key].uri === item) {
        bredcrumbsItems.push({
          link: categories[key].completeUri,
          name: categories[key].name,
        })
      }
    })
  })
  bredcrumbsItems.push({ name: name })

  const tabInfo = []
  if (excerpt) {
    tabInfo.push({
      isOpen: true,
      heading: 'Personal Note',
      text: excerpt,
    })
  }
  if (description) {
    tabInfo.push({
      isOpen: false,
      heading: 'Product Information',
      text: description,
    })
  }
  if (infoFitSize) {
    tabInfo.push({
      heading: 'Fit & Size',
      text: infoFitSize,
    })
  }
  if (infoCareGuide) {
    tabInfo.push({
      heading: 'Care guide',
      text: infoCareGuide,
    })
  }
  if (infoDetails) {
    tabInfo.push({
      heading: 'Production',
      text: infoDetails,
    })
  }
  if (shippingInfo) {
    tabInfo.push({
      heading: 'Shipping',
      richText: shippingInfo,
    })
  }

  const related = relatedProducts
    ? getProductsByIds(relatedProducts.map((item) => item.product))
    : null

  return {
    tabInfo,
    related,
    bredcrumbsItems,
  }
}

export function getPrice(productMarkets: any, country: string): Price {
  const countryData = find(countries, 'country', country)
  if (!countryData) {
    warn(new Error(`Country ${country} does not exist`))
  } else {
    const { market, pricelist } = countryData
    const productMarket = find(productMarkets, 'market', market)
    if (!productMarket) {
      warn(new Error(`Product is unavailable in market ${market}`))
    } else {
      const { pricesByPricelist } = productMarket
      let productPrice = find(pricesByPricelist, 'pricelist', pricelist)
      if (!productPrice) {
        warn(new Error(`Product has no price in pricelist ${pricelist}`))
      } else {
        const pricelistData = find(pricelists, 'pricelist', pricelist)
        if (!pricelistData) {
          warn(new Error(`Pricelist ${pricelist} not found`))
        } else {
          productPrice = Object.assign({}, productPrice, {
            meta: pricelistData,
          })
        }
        return productPrice
      }
    }
  }
  return null
}
